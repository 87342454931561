const darkTheme = {
  textNormal: "#ffffff",
  textHeader: "#828282",
  textHeaderHightlight: "#FDC747",
  bgColor: "#07070B",
};

const lightTheme= {
  textNormal: "#000",
  bgColor: "#fff",
};

export { lightTheme, darkTheme };
