import { Spin } from "antd";
import React, { lazy, Suspense } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Loading = () => (
  <Spin
    indicator={
      <LoadingOutlined
        style={{
          fontSize: 24,
          color: '#F4B840'
        }}
        spin
      />
    }
  />
);

const loadable = (importFunc, { fallback = null } = { fallback: null }) => {
  const LazyComponent = lazy(importFunc);

  return (props) => (
    <Suspense fallback={<Loading />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
