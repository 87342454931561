import i18n from "i18next";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import "./App.css";
import { GlobalStyle } from "./global-style";
import BodyView from "./main/Body/BodyView";
import BottomView from "./main/Bottom/BottomView";
import HeaderView from "./main/Header/HeaderView";
import { COOKIES, LANGUAGE_EN } from "./utils/constants";
import { darkTheme, lightTheme } from "./utils/theme";

export const useDarkMode = () => {
  const [theme, setTheme] = useState("dark");
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  return [theme, toggleTheme];
};

function App() {
  const [theme, toggleTheme] = useDarkMode();

  useEffect(() => {
    window.zaloJSV2 = {
      zalo_h5_event_handler: function (eventId, eventName, eventData) {},
    };
  }, []);

  useEffect(() => {
    const language = Cookies.get(COOKIES.language);
    console.log("language", language);
    i18n.changeLanguage(language || LANGUAGE_EN);
    // if (language === LANGUAGE_VI) {
    // } else {
    //   i18n.changeLanguage(LANGUAGE_EN);
    // }
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <>
          <GlobalStyle />
          <header>
            <HeaderView toggleTheme={toggleTheme} />
          </header>
          <div className="body-main" style={{ paddingTop: 75 }}>
            <BodyView />
          </div>
          <div className="bottom-footer" style={{ width: "100%" }}>
            <BottomView />
          </div>
        </>
      </ThemeProvider>
    </div>
  );
}

export default App;
