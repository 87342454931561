import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import vi from "./locales/vi/index";
import en from "./locales/en/index";
import ja from "./locales/ja/index";
import cn from "./locales/cn/index";
import ko from "./locales/ko/index";

const resources = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
  ja: {
    translation: ja,
  },

  cn: {
    translation: cn,
  },
  ko: {
    translation: ko,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  debug: true,
  interpolation: { escapeValue: false },
});

export default i18n;
