import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, useLocation } from "react-router-dom";
import Hardware from "../../container/hardware/Loadable";
import Services from "../../container/services/Loadable";
import Software from "../../container/software/Loadable";
import IconComingSoon from "../../image/comingSoon.svg";
import { routeContants } from "../Bottom/BottomView";
import IntroView from "./Intro/Loadable";
import Solution1 from "./Solution/Loadable";
import Solution2 from "./Solution/Loadable1";
import Support from "./Support/Loadable";
import "./index.css";

function BodyView() {
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderView = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <img
        src={IconComingSoon}
        alt="icon-cooming-soon"
        className="body-img-comming-soon"
      />
    </div>
  );

  const getView = () => {
    let component;
    switch (location.pathname) {
      case "/":
      case `/${routeContants.generalIntro}`:
      case `/${routeContants.intro}`:
        component = () => <IntroView />;
        break;
      case `/${routeContants.solution_1}`:
        component = () => <Solution1 />;
        break;
      case `/${routeContants.solution_2}`:
        component = () => <Solution2 />;
        break;
      case `/${routeContants.support}`:
        component = () => <Support />;
        break;
      case `/${routeContants.hardware}`:
        component = () => <Hardware />;
        break;
      case `/${routeContants.software}`:
        component = () => <Software />;
        break;
      case `/${routeContants.services}`:
        component = () => <Services />;
        break;
      default:
        component = () => <IntroView />;
        break;
    }
    return component;
  };

  return (
    <div>
      <Route path={location.pathname} render={getView()} />
    </div>
  );
}

export default BodyView;
