import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.bgColor};
    transition: all 200ms;
    header {
      color: ${({ theme }) => theme.textHeader};
      .ant-menu-title-content:hover,
      .ant-menu-submenu-selected > .ant-menu-submenu-title,
      .ant-menu-item-selected {
        color: ${({ theme }) => theme.textHeaderHightlight};
      }
      .ant-menu-title-content {
        color: ${({ theme }) => theme.textHeader};
      }
    }
    .bottom-footer {
      color: ${({ theme }) => theme.textHeader} !important;
      font-size: 12px !important;
    }
  }
  // input:-internal-autofill-selected {
  //   background-color: #ffffff !important;
  // }
  // .ant-input-affix-wrapper:focus-within {
  //   box-shadow: 0 0 0 30px white inset !important;
  // }

  .ant-input-affix-wrapper > input.ant-input:focus {
    box-shadow: 0 0 0 30px white inset !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .indicators {
    padding: 20px !important;
    margin: 0px !important;
  }
`;
