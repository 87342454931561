import { Col, Row } from "antd";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import IconPhone from "../../image/phone.svg";
import IconMail from "../../image/mail.svg";
import IconAddress from "../../image/address.svg";
import IconLogoOther from "../../image/logoOther.svg";
import styled from "styled-components";
import { StyledButton } from "../Header/HeaderView";
import history from "../../utils/history";

const StyledRowInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-bettwen;
  gap: 10px;
  margin-bottom: 30px;
`;

const StyledBlock = styled(Row)`
  flex-direction: column;
  margin-bottom: 30px;
  align-items: flex-start;
`;

export const routeContants = {
  intro: "intro",
  generalIntro: "general-intro",
  partner: "partner",
  support: "support",
  contact: "contact",
  privacyPolicy: "privacy-policy",
  termsOfUse: "terms-of-use",
  ipCamera: "IP-camera",
  aiBox: "AI-box",
  gateway: "gateway",
  bLESeshSmartHome: "BLE-mesh-smart-home",
  smartHomeSolution: "Smart-home-solution",
  gPSMonitoring: "GPS-monitoring",
  hotelManagementSolution: "Hotel-management-solution",
  radioSolutionForTaxi: "4G-radio-solution-for-taxi",
  solution: "solution",
  news: "news",
  recruitment: "recruitment",
  // product
  product: "product",
  hardware: "hardware",
  software: "software",
  // solution
  solution_1: "digo-all-in-one-platform",
  solution_2: "digo-ai-vision",
  services: "services",
};

export const introArr = (t) => [
  {
    key: routeContants.generalIntro,
    text: t("header.generalIntro"),
  },
  // {
  //   key: routeContants.partner,
  //   text: t("header.partner"),
  // },
];

export const productArr = (t) => [
  {
    key: routeContants.hardware,
    text: t("header.hardware"),
  },
  {
    key: routeContants.software,
    text: t("header.software"),
  },
];

export const solutionArr = (t) => [
  {
    key: routeContants.solution_1,
    text: "DIGO All In One Platform",
  },
  {
    key: routeContants.solution_2,
    text: "DIGO AI Vision",
  },
];

export const hardwareArr = (t) => [
  {
    key: `${routeContants.hardware}#hardware-smart-home`,
    text: t("hardware.HARD_WARE_A_06"),
  },
  {
    key: `${routeContants.hardware}#hardware-house-hold`,
    text: t("hardware.HARD_WARE_A_13"),
  },
  {
    key: `${routeContants.hardware}#hardware-location`,
    text: t("hardware.HARD_WARE_A_19"),
  },
  {
    key: `${routeContants.hardware}#hardware-high-speed`,
    text: t("hardware.HARD_WARE_A_21"),
  },
];

export const supportArr = (t) => [
  {
    key: routeContants.support,
    text: t("header.contact"),
  },
  {
    key: routeContants.privacyPolicy,
    text: t("header.privacyPolicy"),
  },
  // {
  //   key: routeContants.support,
  //   text: t("header.termsOfUse"),
  // },
];

export const softwareArr = (t) => [
  {
    key: `${routeContants.software}#software-web`,
    text: t("software.SOFTWARE_A_05"),
  },
  {
    key: `${routeContants.software}#software-app`,
    text: t("software.SOFTWARE_A_06"),
  },
];

function BottomView() {
  const { t } = useTranslation();

  const onClick = (value) => {
    if (value === routeContants.privacyPolicy) {
      window.open("https://iot.digotech.net/privacy-policy-vi", "_blank");
    } else if (value === routeContants.termsOfUse) {
      window.open("https://iot.digotech.net/privacy-policy-vi", "_blank");
    } else {
      history.push(`/${value}`);
      window.scrollTo(0, 0);
    }
  };

  const renderOption = (arr) =>
    arr.map((item) => (
      <StyledButton
        type="text"
        size="large"
        style={{ fontWeight: 700 }}
        onClick={() => {
          onClick(item.key);
        }}
      >
        {item.text}
      </StyledButton>
    ));

  const renderParentOption = (text) => (
    <StyledButton
      type="text"
      size="large"
      style={{
        fontWeight: 700,
        color: "#FDC747",
        cursor: "default",
      }}
    >
      {text.toUpperCase()}
    </StyledButton>
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          backgroundColor: "#292E2E",
          padding: "40px 40px 0 40px",
        }}
      >
        <div style={{ marginBottom: 30 }}>
          <div style={{ marginLeft: -10 }}>
            {renderParentOption(t("bottom.nameCompany"))}
          </div>
          <StyledRowInfo
            style={{
              maxWidth: 282,
              textAlign: "start",
            }}
          >
            <img src={IconAddress} alt="icon-address" />
            <span style={{ fontWeight: 700 }}>
              {t("bottom.addressCompany")}
            </span>
          </StyledRowInfo>
          <StyledRowInfo>
            <img src={IconPhone} alt="icon-phone" />
            <span style={{ fontWeight: 700 }}>+84 904 934 954</span>
          </StyledRowInfo>
          <StyledRowInfo>
            <img src={IconMail} alt="icon-mail" />
            <span style={{ fontWeight: 700 }}>info@digotech.net</span>
          </StyledRowInfo>
        </div>
        <StyledBlock>
          {renderParentOption(t("header.introTitle"))}
          {renderOption(introArr(t))}
        </StyledBlock>
        <StyledBlock>
          {renderParentOption(t("header.solutionTitle"))}
          {renderOption(solutionArr(t))}
        </StyledBlock>
        <StyledBlock>
          {renderParentOption(t("header.hardware"))}
          {renderOption(hardwareArr(t))}
        </StyledBlock>

        <StyledBlock>
          {renderParentOption(t("header.software"))}
          {renderOption(softwareArr(t))}
        </StyledBlock>
        <StyledBlock>
          {renderParentOption(t("header.supportTitle"))}
          {renderOption(supportArr(t))}
        </StyledBlock>
      </div>
      <Row
        style={{
          justifyContent: "center",
          backgroundColor: "#1E2121",
          padding: "40px 15px",
          textAlign: "start",
          gap: 15,
        }}
      >
        <Col span={7}>
          <img
            src={IconLogoOther}
            alt="icon-other"
            style={{ maxWidth: "100%" }}
          />
        </Col>
        <Col span={16}>
          <Trans>{t("bottom.infoDigo")}</Trans>
        </Col>
      </Row>
    </div>
  );
}

export default BottomView;
