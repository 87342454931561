import styled from "styled-components";

export const ListLanguage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LanguageItem = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap:5px;
  background-color: ${({ active }) =>
    active ? "rgba(164, 143, 68, 1)" : "rgba(217, 217, 217, 1)"} ;
`;
