/**
 * Asynchronously loads the component for Customer
 */

import React from "react";
import loadable from "../../../utils/loadable";

export default loadable(() => import("./index"), {
  fallback: <div>Loading...</div>,
});
