import header from "./header.json";
import bottom from "./bottom.json";
import intro from "./intro.json";
import solution1 from "./solution1.json";
import solution2 from "./solution2.json";
import support from "./support.json";
import hardware from "./hardware.json";
import software from "./software.json";
import services from "./services.json";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  header,
  bottom,
  intro,
  solution1,
  solution2,
  support,
  hardware,
  software,
  services,
};
