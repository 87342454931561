export const LANGUAGE_EN = "en";
export const LANGUAGE_JA = "ja";
export const LANGUAGE_VI = "vi";
export const LANGUAGE_CN = "cn";
export const LANGUAGE_KO = "ko";

export const COOKIES = {
  accessToken: "access_token",
  refreshToken: "refresh_token",
  username: "username",
  loginType: "login_type",
  userId: "user_id",
  language: "language",
  languageWebview: "language_webview",
  identifier: "identifier",
  pathHome: "path_home",
};

export const URL_UPLOAD =
  "https://media-iot.digotech.net/digoiot-chvibglj/upload";
