import { Button, Drawer, Flex, Menu, Popover } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import IconJa from "../../image/ja.svg";
import IconEn from "../../image/en.svg";
import IconCn from "../../image/cn.svg";
import IconKo from "../../image/ko.svg";
import IconMenu from "../../image/iconMenu.svg";
import IconLogo from "../../image/logo.svg";
import IconIntro from "../../image/menu/intro.svg";
import IconNews from "../../image/menu/news.svg";
import IconSolution from "../../image/menu/solution.svg";
import IconSupport from "../../image/menu/support.svg";
import IconVi from "../../image/vi.svg";
import {
  COOKIES,
  LANGUAGE_CN,
  LANGUAGE_EN,
  LANGUAGE_JA,
  LANGUAGE_KO,
  LANGUAGE_VI,
} from "../../utils/constants";
import history from "../../utils/history";
import { productArr, routeContants, solutionArr } from "../Bottom/BottomView";
import "./index.css";
import { LanguageItem, ListLanguage } from "./styles";

export const StyledButton = styled(Button)`
  padding: 0px 10px !important;
  color: #828282;
  font-size: 12px !important;
  align-items: flex-start;
  display: flex;
  :hover {
    color: #fdc747 !important;
    background-color: unset !important;
  }
`;

export const ImgIconLanguage = styled.img`
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  :hover {
    transform: scale(1.1);
  }
  // width: 20px;
  // height: 20px;
`;

// export const StyledMenu = styled(Menu)`
//   .ant-menu-title-content {
//     font-weight: 600;
//   }
// `;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0px;
  }
  .ant-drawer-header {
    padding: 16px 0px;
    border-bottom: none;
  }
  .ant-drawer-header-title {
    justify-content: flex-end;
  }
`;
const StyledMenu = styled(Menu)`
  .ant-menu-title-content {
    color: #828282;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  li.active .ant-menu-title-content {
      color: #fdc747;
    }
  

  .ant-menu-submenu:hover::after,
  .ant-menu-submenu-selected::after,
  .ant-menu-item:hover::after,
  .ant-menu-item-selected::after {
    border-bottom-width: 0px !important;
    border-bottom-color: transparent !important;
  }
  .ant-menu-submenu::after {
    border-bottom: none !important;
  }
`;

const listLanguage = (t) => [
  {
    title: t("header.vi"),
    icon: IconVi,
    key: LANGUAGE_VI,
  },
  {
    title: t("header.en"),
    icon: IconEn,
    key: LANGUAGE_EN,
  },
  {
    title: t("header.ja"),
    icon: IconJa,
    key: LANGUAGE_JA,
  },
  {
    title: t("header.cn"),
    icon: IconCn,
    key: LANGUAGE_CN,
  },
  {
    title: t("header.ko"),
    icon: IconKo,
    key: LANGUAGE_KO,
  },
];

const icon_dot = (
  <span
    style={{
      width: 8,
      height: 8,
      borderRadius: "50px",
      backgroundColor: "#905F31",
      minWidth: 8,
    }}
  />
);

function HeaderView(props) {
  // const { toggleTheme } = props;
  const { t } = useTranslation();
  const location = useLocation();

  const [language, setLanguage] = useState(Cookies.get(COOKIES.language));
  const [open, setOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const changeLanguage = (value) => {
    Cookies.set(COOKIES.language, value);
    setLanguage(value);
    if (value !== language) window.location.reload();
  };

  const onClickMenu = (value) => {
    history.push(`/${value}`);
    window.scrollTo(0, 0);
    setOpen(false);
    setSelectedKey(value);
  };

  const onClickSubMenuTitle = (value) => {
    if (window.innerWidth < 1014) {
      return;
    }
    if (value.key === routeContants.product) {
      history.push(routeContants.hardware);
      setSelectedKey(routeContants.hardware);
    }
    if (value.key === routeContants.solution) {
      history.push(routeContants.solution_1);
      setSelectedKey(routeContants.solution_1);
    }
    window.scrollTo(0, 0);
    setOpen(false);
  };

  useEffect(() => {
    const defaultKey = location.pathname.split("/")[1];
    setSelectedKey(defaultKey);
  }, [location.pathname]);

  useEffect(() => {
    const lang = Cookies.get(COOKIES.language);
    if (
      lang !== LANGUAGE_VI &&
      lang !== LANGUAGE_EN &&
      lang !== LANGUAGE_JA &&
      lang !== LANGUAGE_CN &&
      lang !== LANGUAGE_KO
    ) {
      setLanguage(LANGUAGE_VI);
      Cookies.set(COOKIES.language, LANGUAGE_VI);
    } else {
      setLanguage(lang);
    }
  }, []);

  const renderIcon = (src) => (
    <div style={{ width: 40, height: 40, cursor: "pointer" }}>
      <img alt="icon-menu" src={src} style={{ transform: "scale(0.8)" }} />
    </div>
  );

  const LanguageActive = useMemo(() => {
    const valueActive = language || LANGUAGE_VI;
    const dataActive = listLanguage(t).find((el) => el.key === valueActive);
    if (dataActive) {
      return dataActive;
    }
  }, [language, t]);

  const renderActionButton = (style) => (
    <Popover
      placement="bottomRight"
      title=""
      trigger="click"
      overlayClassName="list-language"
      arrow={false}
      content={
        <ListLanguage>
          {listLanguage(t).map((el) => (
            <LanguageItem
              key={el.key}
              active={language === el.key}
              onClick={() => changeLanguage(el.key)}
            >
              <ImgIconLanguage alt="img-language" src={el.icon} />
              <span>{el.title}</span>
            </LanguageItem>
          ))}
        </ListLanguage>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 35,
          alignItems: "center",
          ...style,
        }}
      >
        {/* <ImgIconLanguage src={IconSearch} alt="icon-search" />
      <Tooltip onClick={toggleTheme}>
        <ImgIconLanguage src={IconTheme} alt="icon-theme" />
      </Tooltip> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: "12px",
            cursor: "pointer",
          }}
        >
          <ImgIconLanguage
            alt=""
            src={LanguageActive.icon}
            style={{
              marginRight: 10,
            }}
          />
          <span>{LanguageActive.title}</span>
        </div>
      </div>
    </Popover>
  );

  const checkActive = (value) => {
    return location.pathname === `/${value}` ? "active" : "";
  };
  const checkActiveSolution = () => {
    return location.pathname === `/${routeContants.solution_1}` ||
      location.pathname === `/${routeContants.solution_2}`
      ? "active"
      : "";
  };
  const checkActiveProduct = () => {
    return location.pathname === `/${routeContants.hardware}` ||
      location.pathname === `/${routeContants.software}`
      ? "active"
      : "";
  };

  const menuView = (mode, styleFull) => (
    <StyledMenu
      mode={mode}
      style={{ borderInlineEnd: 0, marginTop: 20, ...styleFull }}
      onClick={({ item, key, keyPath, domEvent }) => {
        onClickMenu(key);
      }}
      selectedKeys={selectedKey}
    >
      <StyledMenu.Item
        key={routeContants.intro}
        icon={mode === "inline" && renderIcon(IconNews)}
        className={
          location.pathname === "/" ||
          location.pathname === `/${routeContants.intro}` ||
          !Object.values(routeContants).includes(
            location?.pathname?.replace("/", "")
          )
            ? "active"
            : ""
        }
      >
        {t("header.introTitle").toUpperCase()}
      </StyledMenu.Item>

      <StyledMenu.SubMenu
        key={routeContants.solution}
        title={t("header.solutionTitle").toUpperCase()}
        icon={mode === "inline" && renderIcon(IconSolution)}
        popupClassName="header-submenu"
        className={checkActiveSolution()}
        onTitleClick={onClickSubMenuTitle}
      >
        {solutionArr(t).map((item) => (
          <StyledMenu.Item key={item.key} icon={mode === "inline" && icon_dot}>
            {item.text.toUpperCase()}
          </StyledMenu.Item>
        ))}
      </StyledMenu.SubMenu>
      <StyledMenu.SubMenu
        key={routeContants.product}
        title={t("header.productTitle").toUpperCase()}
        icon={mode === "inline" && renderIcon(IconIntro)}
        popupClassName="header-submenu"
        className={checkActiveProduct()}
        onTitleClick={onClickSubMenuTitle}
      >
        {productArr(t).map((item) => (
          <StyledMenu.Item key={item.key} icon={mode === "inline" && icon_dot}>
            {item.text.toUpperCase()}
          </StyledMenu.Item>
        ))}
      </StyledMenu.SubMenu>
      <StyledMenu.Item
        key={routeContants.services}
        icon={mode === "inline" && renderIcon(IconNews)}
        className={checkActive(routeContants.services)}
      >
        {t("header.servicesTitle").toUpperCase()}
      </StyledMenu.Item>
      <StyledMenu.Item
        key={routeContants.support}
        icon={mode === "inline" && renderIcon(IconSupport)}
        className={checkActive(routeContants.support)}
      >
        {t("header.supportTitle").toUpperCase()}
      </StyledMenu.Item>
    </StyledMenu>
  );

  const renderViewMenu = () => (
    <StyledDrawer
      placement="left"
      onClose={onClose}
      open={open}
      contentWrapperStyle={{ maxWidth: "70%" }}
    >
      <div>
        {renderActionButton({
          justifyContent: "flex-end",
          gap: 10,
          marginRight: 15,
        })}
        {menuView("inline")}
      </div>
    </StyledDrawer>
  );

  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "#07070B",
        width: "100%",
        zIndex: 100,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <div className="header_view_min">
          <ImgIconLanguage
            onClick={showDrawer}
            src={IconMenu}
            alt="icon-theme"
          />
          {renderViewMenu()}
        </div>
        <img
          className="header_logo"
          src={IconLogo}
          alt="icon-logo"
          style={{ transform: "scale(0.8)", cursor: "pointer" }}
          onClick={() => history.push("/")}
        />
        <Flex gap={37} align="center" className="header_view_full">
          <div
            style={{
              gap: "0 70px",
              justifyContent: "center",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {menuView("horizontal", {
              background: "transparent",
              width: "100%",
              justifyContent: "center",
              gap: "10px",
              fontSize: "20px",
              fontWeight: 700,
              borderBottom: "none",
              marginTop: 0,
              flexWrap: "wrap",
            })}
          </div>
          <div className="header_action-button">{renderActionButton()}</div>
        </Flex>
        {/* <div className="header-icon-search"></div> */}
      </div>
    </div>
  );
}

export default HeaderView;
